<template>
  <div>
    <div class="spinner" v-if="loading">
      <vue-spinner
        size="50"
        :line-size="17"
        text-fg-color="#aa00ff"
        line-fg-color="#aa00ff"
        message="Loading Please wait!"
      ></vue-spinner>
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Spinner from "vue-simple-spinner";

export default {
  props: {
    loading: {
      type: Boolean,
    },
  },
  components: {
    VueSpinner: Spinner,
  },
};
</script>

<style scoped>
.spinner {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
