<template>
  <div class="d-flex">
    <p v-if="patient.name" class="subtitle-1 patient-info">
      Name - {{ patient.name }}
    </p>
    <p
      v-if="patient.treatment_startdate != null"
      class="ml-3 subtitle-1 patient-info"
    >
      Code - {{ patient.reg_unique_code }}
    </p>
  </div>
</template>



<script>
export default {
  props: {
    patient: Object,
  },
};
</script>


<style scoped>
.patient-info {
  letter-spacing: 1px !important;
}
</style>
