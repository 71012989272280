<template>
  <div>
    <v-card class="card--shadow">
      <v-card-title class="purple accent-4">
        <div class="subtitle-1 white--text text-uppercase">
          Conclusion Update
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col lg="6">
            <label>Treatment Finish</label>
            <v-select
              :items="treatmentFinishOpt"
              v-model="patient.treatment_finish"
              item-text="text"
              item-value="value"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col lg="6">
            <label>Finished Date</label>
            <date-picker
              @clearDate="patient.finished_date = null"
              :date="patient.finished_date"
              v-model="patient.finished_date"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="6">
            <label>Final Outcome</label>
            <v-select
              :items="finalOpt"
              v-model="patient.final_outcome"
              :error-messages="handleFieldErrors($v.patient.final_outcome)"
              item-text="text"
              item-value="value"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col lg="6" v-if="patient.final_outcome !== null">
            <label>Outcome Date</label>
            <date-picker
              @clearDate="patient.final_outcome_date = null"
              :date="patient.final_outcome_date"
              v-model="patient.final_outcome_date"
            />
          </v-col>
          <v-col lg="6" v-if="patient.final_outcome_date != null">
            <label>Issue Date</label>
            <date-picker
              @clearDate="patient.issue_date = null"
              :date="patient.issue_date"
              v-model="patient.issue_date"
            />
          </v-col>
          <v-col lg="6" v-if="patient.final_outcome=='4'">
            <label>Reason for lost to follow up</label>
            <v-text-field
              v-model.trim="patient.reason_for_lost"
              type="text"
              dense
              filled
            ></v-text-field>
          </v-col>
          <v-col lg="6" v-if="patient.final_outcome=='3'">
            <label>Cause of death</label>
            <v-text-field
              v-model.trim="patient.cause_of_death"
              type="text"
              dense
              filled
            ></v-text-field>
          </v-col>
          
        </v-row>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-spacer />
        <v-btn @click="updateConclusion" class="form-btn">
          <v-icon left>mdi-chevron-right-circle-outline</v-icon>Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import DatePicker from "@/components/common/DatePicker.vue";
import { ValidationMixin } from "./../../../mixins/ValidationMixin";
import { required } from "vuelidate/lib/validators";

export default {
  components: { DatePicker },
  mixins: [ValidationMixin],
  props: {
    patient: {
      type: Object,
      default: () => {
        return {
          treatment_finish: null,
          treatment_startdate: null,
          final_outcome_date: null,
          final_outcome: null,
          issue_date: null,
          reason_for_lost:null,
          cause_of_death:null,
        };
      },
    },
  },
  data: () => ({
    finishMenu: false,
    outcomeMenu: false,
    issueDateMenu: false,
    treatmentFinishOpt: [
      { value: 1, text: "Stop" },
      { value: 2, text: "Finish" },
      { value: 3, text: "No" },
      { value: null, text: "On-going" },
    ],
    finalOpt: [
      { value: 1, text: "Cured" },
      { value: 2, text: "Completed" },
      { value: 3, text: "Died" },
      { value: 4, text: "Lost to follow up" },
      { value: 5, text: "Failed" },
      { value: 6, text: "Moved to XDR" },
      { value: 7, text: "Not evaluated/ Transfer out" },
      { value: 8, text: "Moved to resident township" },
      { value: null, text: " " },
    ],
  }),
  validations() {
    return {
      patient: {
        final_outcome: { required },
       
      },
    };
  },

  methods: {
    updateConclusion() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
          let data = {
            id: this.$route.params.patientId,
            treatment_finish: this.patient.treatment_finish,
            finished_date: this.patient.finished_date,
            final_outcome: this.patient.final_outcome,
            final_outcome_date: this.patient.final_outcome_date,
            issue_date: this.patient.issue_date,
            reason_for_lost:this.patient.reason_for_lost,
            cause_of_death:this.patient.cause_of_death,
          };

          const outcome = new Date(data.final_outcome_date);
          const issue = new Date(data.issue_date);

          if(issue<outcome)
          {
             this.$toast.error('Issue date should not be greater than outcome date!');
            // alert("Issue date should not be greater than outcome date!");
          }else{
            this.$store.dispatch("Enroll/updateConclusion", data);
            this.$v.$reset();
          }
         
        }
      }
  },
};
</script>
