<template>
  <spinner-container :loading="loading">
    <back-btn />

    <v-container>
      <v-row justify="center">
        <v-col lg="8">
          <v-card class="card--shadow mb-2">
            <v-card-text>
              <patient-info :patient="patient" />
            </v-card-text>
          </v-card>
          <clu-form :patient="patient" />
        </v-col>
      </v-row>
    </v-container>
  </spinner-container>
</template>

<script>
import BackBtn from "@/components/navigation/BackBtn";
import CluForm from "@/components/patient/enroll/CluForm";
import { mapState } from "vuex";
import SpinnerContainer from "@/components/container/SpinnerContainer";
import PatientInfo from "../component/patientInfo.vue";

export default {
  components: {
    CluForm,
    BackBtn,
    SpinnerContainer,
    PatientInfo,
  },
  data: () => ({}),
  computed: {
    ...mapState("Patient", ["patient"]),
    ...mapState("Loading", ["loading"]),
  },
  methods: {},
  created() {
    this.$store.dispatch("Patient/getPatient", this.$route.params.patientId);
  },
};
</script>
